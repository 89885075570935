import { render, staticRenderFns } from "./fileTabs.vue?vue&type=template&id=ece288c0&"
import script from "./fileTabs.vue?vue&type=script&lang=js&"
export * from "./fileTabs.vue?vue&type=script&lang=js&"
import style0 from "./fileTabs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports