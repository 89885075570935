<template>
  <div class="tab-content  mt-3" v-if="this.RegieFiles">
    <Card>
      <template v-slot:body>
        <b-tabs class="files-tabs" lazy>
          <b-tab
            no-body
            title-link-class="files-tab-title"
            v-for="(categorie, index) in categorieFiles"
            :key="'categorie' + index"
          >
            <template class="counter" #title>
              {{ categorie.text }}
              <span v-if="categorie.value == 'tous'">
                {{ getAllFiles.length }}
              </span>
              <span v-if="categorie.value == 'KBIS'">
                {{ getKBIS.length }}
              </span>
              <span v-if="categorie.value == 'Quitus_Fiscal'">
                {{ getQuitus_Fiscal.length }}
              </span>
              <span v-if="categorie.value == 'Attestation_URSAAF'">
                {{ getAttestation_URSAAF.length }}
              </span>
            </template>
            <template>
              <span v-if="categorie.value == 'tous'">
                <File
                  v-for="file in getAllFiles"
                  :key="'ALL' + file.id"
                  :file="file"
                  :delete="deleted"
                  :download="download"
                  :downloadAction="DownloadingRegieDoc"
                  :deleteMethod="deleteRegieFile"
                  :editable="editable"
                  :supprimer="supprimer"
                ></File>
              </span>
              <span v-if="categorie.value == 'KBIS'">
                <File
                  v-for="file in getKBIS"
                  :key="'KBIS' + file.id"
                  :file="file"
                  :delete="deleted"
                  :download="download"
                  :downloadAction="DownloadingRegieDoc"
                  :deleteMethod="deleteRegieFile"
                  :editable="editable"
                  :supprimer="supprimer"
                ></File>
              </span>
              <span v-if="categorie.value == 'Quitus_Fiscal'">
                <File
                  v-for="file in getQuitus_Fiscal"
                  :key="'Quitus_Fiscal' + file.id"
                  :file="file"
                  :delete="deleted"
                  :download="download"
                  :downloadAction="DownloadingRegieDoc"
                  :deleteMethod="deleteRegieFile"
                  :editable="editable"
                  :supprimer="supprimer"
                ></File>
              </span>
              <span v-if="categorie.value == 'Attestation_URSAAF'">
                <File
                  v-for="file in getAttestation_URSAAF"
                  :key="'Attestation_URSAAF' + file.id"
                  :file="file"
                  :delete="deleted"
                  :download="download"
                  :downloadAction="DownloadingRegieDoc"
                  :deleteMethod="deleteRegieFile"
                  :editable="editable"
                  :supprimer="supprimer"
                ></File> </span
            ></template>
          </b-tab>
        </b-tabs>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    RegieFiles: {},
    options: {
      type: Array
    },
    deleted: {},
    download: {},
    editable: { default: true },
    supprimer: { default: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['DownloadingRegieDoc', 'deleteRegieFile']),
    compteType(value) {
      if (value === 'tous' && this.getAllFiles.length > 0) {
        return true;
      }
      if (value === 'KBIS' && this.getKBIS.length > 0) {
        return true;
      }
      if (value === 'Quitus_Fiscal' && this.getQuitus_Fiscal.length > 0) {
        return true;
      }
      if (
        value === 'Attestation_URSAAF' &&
        this.getAttestation_URSAAF.length > 0
      ) {
        return true;
      }
      return false;
    }
  },
  computed: {
    categorieFiles() {
      return [{ value: 'tous', text: 'Tous' }, ...this.options];
    },
    getAllFiles() {
      if (this.RegieFiles) {
        return [
          ...this.RegieFiles.files.Attestation_URSAAF,
          ...this.RegieFiles.files.KBIS,
          ...this.RegieFiles.files.Quitus_Fiscal
        ];
      } else {
        return [];
      }
    },
    getKBIS() {
      return this.RegieFiles.files.KBIS;
    },
    getQuitus_Fiscal() {
      return this.RegieFiles.files.Quitus_Fiscal;
    },
    getAttestation_URSAAF() {
      return this.RegieFiles.files.Attestation_URSAAF;
    }
  },
  components: {
    File: () => import('../component/Files'),
    Card: () => import('../component/card')
  }
};
</script>

<style lang="scss">
.tab-content {
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      // background-color: #f3f3f3;
      border-radius: 5px;
      padding: 0;
      margin: 8px 0;
      border: none;
      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        border: none;
        //background-color: #e5e5e5;
        background-color: #e4dfdf;
        //border: 1px solid #d5d5d5;
        border-radius: 4px;
        padding: 0px 10px;
        margin: 2px 4px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: #5e5e5e;
          // background-color: #fff;
          background-color: #6f42c138;
          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
</style>
